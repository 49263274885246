<script>
import { Link } from "svelte-routing"
</script>

<div class="flex flex-row gap-4 p-5">
  <Link to="/reports/starseed" class="underline">Starseed</Link>
  <Link to="/reports/starseed-temp" class="underline">Starseed Temp</Link>
  <Link to="/reports/starseed-privacy-policy-offenses" class="underline"
    >Starseed Commercial Policy Offenses</Link
  >
  <Link to="/reports/purchases" class="underline">Purchases</Link>
</div>
